exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-chronogg-desktop-js": () => import("./../../../src/pages/projects/chronogg-desktop.js" /* webpackChunkName: "component---src-pages-projects-chronogg-desktop-js" */),
  "component---src-pages-projects-profile-post-it-js": () => import("./../../../src/pages/projects/profile-post-it.js" /* webpackChunkName: "component---src-pages-projects-profile-post-it-js" */),
  "component---src-pages-projects-right-click-raid-js": () => import("./../../../src/pages/projects/right-click-raid.js" /* webpackChunkName: "component---src-pages-projects-right-click-raid-js" */),
  "component---src-pages-projects-simple-armory-toy-box-js": () => import("./../../../src/pages/projects/simple-armory-toy-box.js" /* webpackChunkName: "component---src-pages-projects-simple-armory-toy-box-js" */),
  "component---src-pages-projects-twitch-favourites-js": () => import("./../../../src/pages/projects/twitch-favourites.js" /* webpackChunkName: "component---src-pages-projects-twitch-favourites-js" */),
  "component---src-pages-projects-wtf-is-on-steam-js": () => import("./../../../src/pages/projects/wtf-is-on-steam.js" /* webpackChunkName: "component---src-pages-projects-wtf-is-on-steam-js" */)
}

